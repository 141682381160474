<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <body-card>
          <template v-slot:body>
                <b-overlay :show="loading">
                    <b-row>
                        <b-col xl="12" lg="12" sm="12">
                            <b-row>
                              <b-col sm="12">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="12"
                                          label-for="gdn_remarks_id"
                                          >
                                          <template v-slot:label>
                                              <b>{{$t('globalTrans.remarks')}}</b>
                                          </template>
                                          <p>{{ currentLocale === 'en' ? item?.gdn_remarks_en : item?.gdn_remarks_bn }}</p>
                                      </b-form-group>
                              </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-overlay>
          </template>
        </body-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>

export default {
    name: 'FormLayout',
    props: ['id', 'item'],
    created () {
    },
    watch: {
      currentLocale: function (newVal, oldVal) {
        if (newVal !== oldVal) {
            this.localizeUserList(newVal)
        }
      },
      'data.gdn_remarks_id': function (newVal, oldVal) {
        if (newVal) {
          this.getRemarksName(newVal)
        } else {
          this.data.gdn_remarks_en = ''
          this.data.gdn_remarks_bn = ''
        }
      }
    },
    computed: {
        loading () {
          return this.$store.state.commonObj.loading
        },
        currentLocale () {
            return this.$i18n.locale
        },
        masterBtriRemarksList () {
          // 2 => btri, 4 => garden
          return this.$store.state.TeaGardenService.commonObj.masterRemarkList.filter(item => item.status === 1 && JSON.parse(item.type).includes(2) && JSON.parse(item.type).includes(4))
        }
    },
    data () {
        return {
            saveBtnName: this.$t('globalTrans.submit'),
            loader: false
        }
    },
    methods: {
      localizeUserList (locale) {
          const tmp = this.userList.map(item => {
              return Object.assign({}, item, {
                  text: locale === 'en' ? item.text_en : item.text_bn
              })
          })
          this.userList = tmp
      }
    }
}
</script>
